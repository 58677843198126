import {environment} from '../../../environments/environment';
import {RequestService} from '../services/request/request.service';
import {ErrorHandlerService} from '../services/error-handler/error-handler.service';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {firstValueFrom} from 'rxjs';
import {DataRequestServiceResponse} from '../services/request/request-response.interface';

export interface IOrganizationState {
  rfcIsAvailable: boolean;
  emailBelongsToAnOrganization: boolean;
}

const _DEFAULT_DATA: IOrganizationState = {
  rfcIsAvailable: false,
  emailBelongsToAnOrganization: false,
}

export namespace OrganizationActions {
  export class GetOrganizationRFCExists {
    static readonly type: string = '[Organization] GetOrganizationRFCExists';
    constructor(public rfc: string, public organizationId?: string) {
    }
  }

  export class GetEmailBelongsToAnOrganization {
    static readonly type: string = '[Organization] GetEmailBelongsToAnOrganization';
    constructor(public email: string) {
    }
  }
}

@State<IOrganizationState>({
  name: 'OrganizationState',
  defaults: _DEFAULT_DATA
})
@Injectable()
export class OrganizationState {
  private readonly SERVER: string = environment.SERVER;
  constructor(
    private requestService: RequestService,
    private errorHandlerService: ErrorHandlerService
  ) { }

  @Selector()
  static getRFCIsAvailable({rfcIsAvailable}: IOrganizationState): boolean {
    return rfcIsAvailable;
  }

  @Selector()
  static getEmailBelongsToAnOrganization({emailBelongsToAnOrganization}: IOrganizationState): boolean {
    return emailBelongsToAnOrganization;
  }

  @Action(OrganizationActions.GetOrganizationRFCExists)
  async getOrganizationRFCExists(
    { patchState }: StateContext<IOrganizationState>,
    {rfc, organizationId}: OrganizationActions.GetOrganizationRFCExists
  ): Promise<void> {
    const response: DataRequestServiceResponse<{ available: boolean }> = await firstValueFrom(this.requestService.get<{ available: boolean }>(`${this.SERVER}/organizations/rfc?rfc=${rfc.toUpperCase()}${organizationId ? ('&organizationId='+organizationId) : ''}`));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    patchState({
      rfcIsAvailable: response.entity?.available
    });
  }

  @Action(OrganizationActions.GetEmailBelongsToAnOrganization)
  async getEmailBelongsToAnOrganization(
    { patchState }: StateContext<IOrganizationState>,
    {email}: OrganizationActions.GetEmailBelongsToAnOrganization
  ): Promise<void> {
    const response: DataRequestServiceResponse<{ belongs: boolean }> = await firstValueFrom(this.requestService.get<{ belongs: boolean }>(`${this.SERVER}/organizations/${encodeURIComponent(email)}/belongs`));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    patchState({
      emailBelongsToAnOrganization: response.entity!.belongs
    });
  }
}
