import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext} from '@ngxs/store';

export namespace LoaderActions {
  const state: string = 'Loader';

  export class Show {
    static readonly type: string = `[${state}] Show`;
    constructor() {}
  }

  export class Hide {
    static readonly type: string = `[${state}] Hide`;
    constructor() {}
  }
}


export interface ILoaderState {
  active: boolean;
}

@State<ILoaderState>({
  name: 'LoaderState',
  defaults: {
    active: false,
  }
})
@Injectable()
export class LoaderState {
  constructor() { }

  @Selector()
  static active({active}: ILoaderState): boolean {
    return active;
  }


  @Action(LoaderActions.Show)
  async show({patchState}: StateContext<ILoaderState>): Promise<void> {
    patchState({active: true});
  }

  @Action(LoaderActions.Hide)
  async hide({patchState}: StateContext<ILoaderState>): Promise<void> {
    patchState({active: false});
  }

}
