import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {
  BadRequestException,
  ErrorException,
  InternalClientException,
  InternalServerException,
  NotFoundException,
} from './class';
import {SERVER_MESSAGE, SERVER_MESSAGE_TRANSLATE} from './const';


@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {

  constructor() {}

  createRequestException(httpErrorResponse: HttpErrorResponse, entityMessage?: string): ErrorException {
    const message: string = (httpErrorResponse?.error?.message ?? '').toString();
    const status: number = httpErrorResponse?.status ?? -1;

    if (message) {
      const serverMessage: SERVER_MESSAGE.IServerMessage | null = SERVER_MESSAGE.find(message);
      if (serverMessage) {
        throw new InternalClientException(SERVER_MESSAGE_TRANSLATE.find(serverMessage.shortCode))};
    }

    if (!navigator.onLine) return new InternalClientException('¡Sin Internet! Verifica tu conexión.');
    if (status === 404) return new NotFoundException();
    if (400 <= status && status < 500) return new BadRequestException();
    if (500 <= status) {
      console.warn('500: ', httpErrorResponse.url, httpErrorResponse.message);
      throw new InternalServerException();
    }
    if (status === -1) {
      console.warn('-1: ', httpErrorResponse.url, httpErrorResponse.message);
      throw new InternalServerException();
    }

    throw new InternalClientException(message ?? entityMessage ?? 'Ha ocurrido un error inesperado. Por favor, intenta nuevamente más tarde.');
  }
}
