export enum ErrorTypeEnum {
  GENERAL = 'GENERAL',
  NOT_FOUND = 'NOT_FOUND',
  BAD_REQUEST = 'BAD_REQUEST',
  INVALID_FORM = 'INVALID_FORM',
  UNSAFE_PASSWORD = 'UNSAFE_PASSWORD',
  INTERNAL_CLIENT = 'INTERNAL_CLIENT',
  UNAUTHORIZED = 'UNAUTHORIZED',
  FORBIDDEN = 'FORBIDDEN',
  INTERNAL_SERVER = 'INTERNAL_SERVER',
  FUNCTIONALITY_IN_PROCESS = 'FUNCTIONALITY_IN_PROCESS',
}
