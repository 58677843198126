import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {isObject as _isObject, omit} from 'lodash';
import {catchError, Observable, Observer, of, retry, RetryConfig} from 'rxjs';
import {PrismaCountFilter, PrismaFilter} from './prisma.interface';
import {IAnyObject} from '../../interfaces';
import {HttpOptions} from './http-options.interface';
import {RemoveArray} from './type/prisma.type';
import {DataRequestServiceResponse} from './request-response.interface';
import {RETRY_STRATEGY_CONST} from './retry-strategy.const';

@Injectable({
  providedIn: 'root',
})
export class RequestService {

  constructor(private httpClient: HttpClient) {}

  private stringifyFilters<T>(filter?: PrismaFilter<T>, params?: IAnyObject): IAnyObject<string> {
    const stringifyFilters: IAnyObject = {};
    if (filter) {
      Object.entries(filter).forEach(([key, value]) => {
        stringifyFilters[key] = _isObject(value) ? JSON.stringify(value) : value;
      });
    }

    if (params) {
      Object.entries(params).forEach(([key, value]) => {
        stringifyFilters[key] = _isObject(value) ? JSON.stringify(value) : value;
      });
    }

    return stringifyFilters;
  }

  private buildHttpOptions<T>(
    httpOptions?: Omit<HttpOptions, 'observe'>,
    filters?: PrismaFilter<RemoveArray<T>>,
    authorization?: string,
  ): HttpOptions {
    const options: HttpOptions = httpOptions ?? {};
    options.params = this.stringifyFilters(filters, httpOptions);
    options.observe = 'body';

    if (authorization) {
      options.headers = new HttpHeaders({
        ...httpOptions?.headers ?? {},
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authorization}`,
      });
    }

    return options;
  }


  private responseStructure<T>(response: T | HttpErrorResponse): DataRequestServiceResponse<T> {
    if (response instanceof HttpErrorResponse) {
      let message = 'Error desconocido';

      if (response.error) {
        if (typeof response.error === 'object' && 'message' in response.error) {
          message = response.error.message;
        } else if (typeof response.error === 'string') {
          message = response.error;
        }
      }

      return {
        error: true,
        code: response.status,
        message: message,
        entity: null,
        serverResponse: response,
      };
    }

    // Manejo para respuestas exitosas
    return {
      error: false,
      code: 200,
      message: 'ok',
      serverResponse: response,
      entity: response,
    };
  }


  private execute<T>(
    requestMethod: (path: string, body: any, options: HttpOptions) => Observable<T>,
    path: string,
    entity: any,
    filters?: PrismaFilter<RemoveArray<T>>,
    httpOptions?: Omit<HttpOptions, 'observe'>,
    authorization?: string,
    retryStrategy: RetryConfig = RETRY_STRATEGY_CONST,
  ): Observable<DataRequestServiceResponse<T>> {
    return new Observable((observer: Observer<DataRequestServiceResponse<T>>): void => {
      const options: HttpOptions = this.buildHttpOptions(httpOptions, filters, authorization);
      requestMethod(path, entity, options)
        .pipe(retry(retryStrategy), catchError((error) => of(error)))
        .subscribe((response: T | HttpErrorResponse) => {
          observer.next(this.responseStructure(response));
          observer.complete();
        });
    });
  }




  get<T>(
    path: string,
    filters?: PrismaFilter<RemoveArray<T>>,
    httpOptions?: Omit<HttpOptions, 'observe'>,
    authorization?: string,
    retryStrategy: RetryConfig = RETRY_STRATEGY_CONST,
  ): Observable<DataRequestServiceResponse<T>> {
    return this.execute<T>((p, _, o) => this.httpClient.get<T>(p, o), path, null, filters, httpOptions, authorization, retryStrategy);
  }
  post<T>(
    path: string,
    entity: any,
    filters?: PrismaFilter<RemoveArray<T>>,
    httpOptions?: Omit<HttpOptions, 'observe'>,
    authorization?: string,
    retryStrategy: RetryConfig = RETRY_STRATEGY_CONST,
  ): Observable<DataRequestServiceResponse<T>> {
    return this.execute<T>((p, e, o) => this.httpClient.post<T>(p, e, o), path, entity, filters, httpOptions, authorization, retryStrategy);
  }


  put<T>(
    path: string,
    entity: any,
    filters?: PrismaFilter<RemoveArray<T>>,
    httpOptions?: Omit<HttpOptions, 'observe'>,
    authorization?: string,
    retryStrategy: RetryConfig = RETRY_STRATEGY_CONST,
  ): Observable<DataRequestServiceResponse<T>> {
    return this.execute<T>((p, e, o) => this.httpClient.put<T>(p, e, o), path, entity, filters, httpOptions, authorization, retryStrategy);
  }

  patch<T>(
    path: string,
    entity: any,
    filters?: PrismaFilter<RemoveArray<T>>,
    httpOptions?: Omit<HttpOptions, 'observe'>,
    authorization?: string,
    retryStrategy: RetryConfig = RETRY_STRATEGY_CONST,
  ): Observable<DataRequestServiceResponse<T>> {
    return this.execute<T>((p, e, o) => this.httpClient.patch<T>(p, e, o), path, entity, filters, httpOptions, authorization, retryStrategy);
  }


  delete<T>(
    path: string,
    filters?: PrismaFilter<RemoveArray<T>>,
    httpOptions?: Omit<HttpOptions, 'observe'>,
    authorization?: string,
    retryStrategy: RetryConfig = RETRY_STRATEGY_CONST,
  ): Observable<DataRequestServiceResponse<T>> {
    return this.execute<T>((p, _, o) => this.httpClient.delete<T>(p, o), path, null, filters, httpOptions, authorization, retryStrategy);
  }

  download(
    path: string,
    mimeType: string = 'application/octet-stream',
    authorization?: string,
    retryStrategy: RetryConfig = RETRY_STRATEGY_CONST,
  ): Observable<DataRequestServiceResponse<Blob>> {
    return new Observable((observer: Observer<DataRequestServiceResponse<Blob>>): void => {
      this.httpClient
        .get(path, {
          observe: 'body',
          responseType: 'blob',
          headers: new HttpHeaders({
            Authorization: `Bearer ${authorization}`,
            'Content-Type': mimeType,
            'Accept': mimeType,
          }),
        })
        .pipe(retry(retryStrategy), catchError((error) => of(error)))
        .subscribe(async (response: Blob | HttpErrorResponse): Promise<void> => {
          observer.next(this.responseStructure<Blob>(response));
          observer.complete();
        });
    });
  }




  count<T>(
    path: string,
    filters?: PrismaCountFilter<RemoveArray<T>>,
    httpOptions?: Omit<HttpOptions, 'observe'>,
    authorization?: string,
    retryStrategy: RetryConfig = RETRY_STRATEGY_CONST,
  ): Observable<DataRequestServiceResponse<number>> {
    // @ts-ignore
    return this.execute((p, _, o) => this.httpClient.get<number>(p, o), path, null, omit(filters, ['take', 'skip', 'include']), httpOptions, authorization, retryStrategy);
  }
}
