import {ICountryWithRelations} from '../../interfaces';
import {PrismaFilter} from '../../services/request/prisma.interface';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {cloneDeep} from 'lodash';
import {Injectable} from '@angular/core';
import {RequestService} from '../../services/request/request.service';
import {firstValueFrom} from 'rxjs';
import {DataRequestServiceResponse} from '../../services/request/request-response.interface';
import {environment} from '../../../../environments/environment';
import {ErrorHandlerService} from '../../services/error-handler/error-handler.service';

export interface ICountryState {
  list: ICountryWithRelations[];
}

const _DEFAULT_DATA: ICountryState = {
  list: []
}

export namespace CountryActions {
  export class Get {
    static readonly type: string = '[Country] Get Countries';
    constructor(public filters?: PrismaFilter<ICountryWithRelations>) {
    }
  }
}

@State<ICountryState>({
  name: 'CountryState',
  defaults: cloneDeep(_DEFAULT_DATA),
})

@Injectable()
export class CountryState {
  private readonly SERVER: string = environment.SERVER

  constructor(
    private requestService: RequestService,
    private errorHandlerService: ErrorHandlerService,

  ) {}

  @Selector()
  static getList(state: ICountryState): ICountryWithRelations[] {
    return state.list;
  }

  @Action(CountryActions.Get)
  async get(
    {patchState}: StateContext<ICountryState>,
    {filters}: CountryActions.Get
  ): Promise<void> {
    const response: DataRequestServiceResponse<ICountryWithRelations[]> = await firstValueFrom(this.requestService.get<ICountryWithRelations[]>(`${this.SERVER}/countries`, filters));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      list: response.entity ?? [],
    });
  }
}
