import {ICityWithRelations, ICountryWithRelations} from '../../interfaces';
import {PrismaFilter} from '../../services/request/prisma.interface';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {cloneDeep} from 'lodash';
import {Injectable} from '@angular/core';
import {RequestService} from '../../services/request/request.service';
import {firstValueFrom} from 'rxjs';
import {DataRequestServiceResponse} from '../../services/request/request-response.interface';
import {environment} from '../../../../environments/environment';
import {ErrorHandlerService} from '../../services/error-handler/error-handler.service';

export interface ICityState {
  list: ICityWithRelations[];
}

const _DEFAULT_DATA: ICityState = {
  list: []
}

export namespace CityActions {
  export class Get {
    static readonly type: string = '[city] Get Cities';
    constructor(public filters?: PrismaFilter<ICityWithRelations>) {
    }
  }
}

@State<ICityState>({
  name: 'CityState',
  defaults: cloneDeep(_DEFAULT_DATA),
})

@Injectable()
export class CityState {
  private readonly SERVER: string = environment.SERVER

  constructor(
    private requestService: RequestService,
    private errorHandlerService: ErrorHandlerService
  ) {}

  @Selector()
  static getList(state: ICityState): ICityWithRelations[] {
    return state.list;
  }

  @Action(CityActions.Get)
  async get(
    {patchState}: StateContext<ICityState>,
    {filters}: CityActions.Get
  ): Promise<void> {
    const response: DataRequestServiceResponse<ICityWithRelations[]> = await firstValueFrom(this.requestService.get<ICityWithRelations[]>(`${this.SERVER}/cities`, filters));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      list: response.entity ?? [],
    });
  }
}
