import {IEventWithRelations} from '../interfaces/event.interface';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {cloneDeep} from 'lodash';
import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {RequestService} from '../services/request/request.service';
import {DataRequestServiceResponse} from '../services/request/request-response.interface';
import {firstValueFrom} from 'rxjs';
import {ErrorHandlerService} from '../services/error-handler/error-handler.service';

export interface IEventState {
  list: IEventWithRelations[];
}

const _DEFAULT_DATA: IEventState = {
  list: []
}

export namespace EventActions {
  export class Get {
    static readonly type: string = '[Event] Get Public Events';
    constructor(public eventId: string) {
    }
  }
}

@State<IEventState>({
  name: 'EventState',
  defaults: cloneDeep(_DEFAULT_DATA),
})

@Injectable()
export class EventState {
  private readonly SERVER: string = environment.SERVER;

  constructor(
    private requestService: RequestService,
    private errorHandlerService: ErrorHandlerService,
  ) {}

  @Selector()
  static getList(state: IEventState): IEventWithRelations[] {
    return state.list;
  }

  @Action(EventActions.Get)
  async get(
    {patchState}: StateContext<IEventState>,
    {eventId}: EventActions.Get
  ): Promise<void> {
    const response: DataRequestServiceResponse<IEventWithRelations> = await firstValueFrom(this.requestService.get<IEventWithRelations>(`${this.SERVER}/events/${eventId}/public`,));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    patchState({
      list: response.entity ? [response.entity] : [],
    });
  }
}
