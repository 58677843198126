import {ErrorException} from './error.exception';
import {ErrorTypeEnum} from '../enums/error-type.enum';

export class BadRequestException extends ErrorException {
  constructor(message: string = 'Solicitud incorrecta', internalCode?: string) {
    super(message);
    this.name = ErrorTypeEnum.BAD_REQUEST;
    this.httpCode = 400;
    this.internalCode = internalCode;
  }
}
