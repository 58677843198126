import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {environment} from '../../../environments/environment';
import {RequestService} from '../services/request/request.service';
import {ErrorHandlerService} from '../services/error-handler/error-handler.service';
import {DataRequestServiceResponse} from '../services/request/request-response.interface';
import {firstValueFrom} from 'rxjs';
import {IUserExists} from '../interfaces';

export interface IUserState {
  exists: IUserExists | null;
}

const _DEFAULT_DATA: IUserState = {
  exists: null,
}

export namespace UserActions {
  const state: string = 'User';
  export class UserExistsByEmail {
    static readonly type: string = `[${state}] UserExistsByEmail`;
    constructor(public email: string) {}
  }
}

@State<IUserState>({
  name: 'UserState',
  defaults: _DEFAULT_DATA
})
@Injectable()
export class UserState {
  private readonly SERVER: string = environment.SERVER;
  constructor(
    private requestService: RequestService,
    private errorHandlerService: ErrorHandlerService
  ) { }

  @Selector()
  static getUserExists({ exists }: IUserState): IUserExists | null {
    return exists;
  }

  @Action(UserActions.UserExistsByEmail)
  async userExistsByEmail(
    { patchState }: StateContext<IUserState>,
    {email}: UserActions.UserExistsByEmail
  ): Promise<void> {
    const response: DataRequestServiceResponse<IUserExists> = await firstValueFrom(this.requestService.get<IUserExists>(`${this.SERVER}/users/email/${encodeURIComponent(email)}/exists`));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    patchState({
      exists: response.entity,
    });
  }

}
