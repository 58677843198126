import {ErrorException} from './error.exception';
import {ErrorTypeEnum} from '../enums/error-type.enum';

export class NotFoundException extends ErrorException {
  constructor(message: string = 'Recurso no encontrado', internalCode?: string) {
    super(message);
    this.name = ErrorTypeEnum.NOT_FOUND;
    this.httpCode = 404;
    this.internalCode = internalCode;
  }
}
