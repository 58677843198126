import {ErrorException} from './error.exception';
import {ErrorTypeEnum} from '../enums/error-type.enum';

export class GeneralException extends ErrorException {
  constructor(message: string = 'Por favor, inténtalo de nuevo más tarde', internalCode?: string) {
    super(message);
    this.name = ErrorTypeEnum.GENERAL;
    this.internalCode = internalCode;
  }
}
