import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {RequestService} from '../services/request/request.service';
import {DataRequestServiceResponse} from '../services/request/request-response.interface';
import {firstValueFrom} from 'rxjs';
import {IUserOrganization} from '../interfaces';
import {ErrorHandlerService} from '../services/error-handler/error-handler.service';
import {IPreEventRegister} from '../../event/interfaces/pre-event-register.interface';

export interface IUserOrganizationState {
  redirectTo: string;
  exists: boolean;
}

const _DEFAULT_DATA: IUserOrganizationState = {
  redirectTo: '',
  exists: false,
}

export namespace UserOrganizationActions {

  export class Register {
    static readonly type: string = '[UserOrganization] User And Organization Register';
    constructor(public data: IUserOrganization) {}
  }

  export class PreRegister {
    static readonly type: string = '[UserOrganization] Pre Register User And Organization';
    constructor(public eventId: string, public data: IPreEventRegister) {}
  }

  export class PreRegisterExists {
    static readonly type: string = '[UserOrganization] Pre Register Exists User And Organization';
    constructor(public eventId: string, public email: string) {}
  }
}

@State<IUserOrganizationState>({
  name: 'UserOrganizationState',
  defaults: _DEFAULT_DATA
})
@Injectable()
export class UserOrganizationState {
  private readonly SERVER: string = environment.SERVER;
  constructor(
    private requestService: RequestService,
    private errorHandlerService: ErrorHandlerService
  ) {
  }

  @Selector()
  static getUrlToRedirect(state: IUserOrganizationState): string {
    return state.redirectTo;
  }

  @Selector()
  static getExists(state: IUserOrganizationState): boolean {
    return state.exists;
  }


  @Action(UserOrganizationActions.Register)
  async register(
    { patchState }: StateContext<IUserOrganizationState>,
    {data}: UserOrganizationActions.Register
  ): Promise<void> {
    const response: DataRequestServiceResponse<{ url: string }> = await firstValueFrom(this.requestService.post<{url: string}>(`${this.SERVER}/users/organizations`, data));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    patchState({
      redirectTo: response.entity?.url ?? ''
    })
  }

  @Action(UserOrganizationActions.PreRegister)
  async preRegister(
    _: StateContext<IUserOrganizationState>,
    {data, eventId}: UserOrganizationActions.PreRegister
  ): Promise<void> {
    const response: DataRequestServiceResponse<IUserOrganization> = await firstValueFrom(this.requestService.post<IUserOrganization>(`${this.SERVER}/events/${eventId}/pre-register`, data));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
  }

  @Action(UserOrganizationActions.PreRegisterExists)
  async preRegisterExists(
    { patchState }: StateContext<IUserOrganizationState>,
    {email, eventId}: UserOrganizationActions.PreRegisterExists
  ): Promise<void> {
    const response: DataRequestServiceResponse<{ exists: boolean }> = await firstValueFrom(this.requestService.get<{ exists: boolean }>(`${this.SERVER}/events/${eventId}/pre-register/${encodeURIComponent(email)}/exists`));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    patchState({ exists: response.entity?.exists });
  }

}
